import { PageBlock } from '../../styles/main-block';

import s from './about-page.module.scss';

export const AboutPage = () => (
  <PageBlock className={s.content}>
    <h1>О сервере</h1>

    <section>
      <h2>Proteus Low</h2>
      <ul>
        <li>Версия: Season 6 Episode 3 + Customs</li>
        <li>Рейт на опыт: x30</li>
        <li>Рейт на опыт ML: 10%</li>
        <li>Рейт на дроп: 30%</li>
        <li>Максимальный уровень: 400</li>
        <li>Максимальный мастер уровень: 400</li>
        <li>Max статов: 32.000</li>
        <li>Mu Helper: с 10 уровня</li>
        <li>Эльф помошник до: 5 ресета</li>
        <li>Максимальный Reset: 30</li>
        <li>Максимальный Grand Reset: 10</li>
        <li>Максимум окон игры: 5</li>
        <li>MG доступны с: 1 уровня</li>
        <li>DL-RF доступны с: 1 уровня</li>
        <li>SUM доступен с: 1 уровня</li>
      </ul>
    </section>

    <section>
      <h2>Proteus Medium</h2>
      <ul>
        <li>Версия: Season 6 Episode 3 + Customs</li>
        <li>Рейт на опыт: x100</li>
        <li>Рейт на опыт ML: 30%</li>
        <li>Рейт на дроп: 30%</li>
        <li>Максимальный уровень: 400</li>
        <li>Максимальный мастер уровень: 400</li>
        <li>Max статов: 32.000</li>
        <li>Mu Helper: с 10 уровня</li>
        <li>Эльф помошник до: 5 ресета</li>
        <li>Максимальный Reset: 100</li>
        <li>Максимальный Grand Reset: 5</li>
        <li>Максимум окон игры: 5</li>
        <li>MG доступны с: 1 уровня</li>
        <li>DL-RF доступны с: 1 уровня</li>
        <li>SUM доступен с: 1 уровня</li>
      </ul>
    </section>
    <section>
      <h2>Особенности игры:</h2>
      <ul>
        <li>Full HD графика, новая 3D камера</li>
        <li>Новая система рангов предметов</li>
        <li>Новые карты до 15 сезона</li>
        <li>Новые наборы вещей до 15 сезона</li>
        <li>Новые Крылья 2.5 - 3.5 - 4 - 5 - 6 уровня</li>
        <li>Новые крафты для создания - новых камней, петов, пентаграм, сетов, оружий, крыльев</li>
        <li>Новые Ancient сеты все сеты из 5 частей Helm, Armor, Pants, Gloves, Boots</li>
        <li>Новые Socket сеты оружия щиты</li>
        <li>Новая Munn система с питомцами, конями, волками, птицами</li>
        <li>Новые щиты 15 сезона</li>
        <li>Новые эффекты новых вещей, оружий, крыльев, боссов</li>
        <li>Party Search система</li>
        <li>Search store система</li>
        <li>Кнопки ресета и гранд ресета с описанием требований, а так же Кузница, Jewel Bank</li>
        <li>Lucky Coin System</li>
        <li>Вознаграждение за онлайн</li>
        <li>4 дополнительных сундука</li>
        <li>Поддержка опций Ancient + PvP + Harmony + Excellent</li>
        <li>Socket вещи до 5 опций + PvP + Excellent</li>
        <li>Поддержка pvp опций на всех вещах</li>
        <li>OffExp - AFK система, прокачка в оффлайне</li>
        <li>OffHelper - AFK система, прокачка в оффлайне с поднятием предметов</li>
        <li>Игровая квест система 200 заданий</li>
        <li>Вся ценная игровая валюта WC доступна заголосование за сервер и убийство сложных мобов</li>
        <li>VIP система с повышением опыта, дропа, а также микс предметов</li>
      </ul>
      <br />
      <p>*Информация может быть дополнена в любой момент.</p>
    </section>
    <section>
      <p>
        Все ресеты и ГРесеты делаются в игре.
        <br />
        Стоимость ресета = 30кк зен * кол-во ресетов
        <br />
        Для ресета вам необходим будет для ресета камень Jewel of Reset который падает по всему миру.
        <br />
        Если по каким то причинам у вас нет данного камня, то вы можете продать в магазин 5*Jewel of Creation за 5*1 WCP
        <br />
        и в Х-шопе купить jewel of reset или купить его за 100кк в Аркании.
        <br />
        <br />
        Гранд Ресет делается при наличии у вас в инвентаре 1ккк зен и 30b 30s 30с
        <br />
        Все персонажи при ГР получают:
        <br />
        10000 Stats * кол-во ГР
        <br />
        100 WcoinC
        <br />
        500 WcoinP
        <br />
        1000 Goblin Points
        <br />
        100 Credits
        <br />
        <br />
        /plset Имя - Меняет пати лидера
        <br />
        /clearinv - Чистит ВЕСЬ инвентарь ( даже то, что надето на вас , так что будьте осторожны )
        <br />
        /ex Количество - Переводит WcoinC в Кредиты по курсу 1:1.
        <br />
        /exс Количество - Переводит Кредиты в WcoinC по курсу 1:1.
        <br />
        /dcfriend логин пароль - Выкидывает из игры вашего друга ( должен быть в списке друзей)
        <br />
        <br />
        В игре существует РАНГ предметов. Таким образом Мы добились балланса на разных ресетах и статах. При заточке
        предметов Ранг растет и ехе статы растут вместе с ним.
        <br />
        <br />
        За Онлайн часы вам начисляется 10 GP/час
        <br />
        <br />
        BC - Jewel DROP
        <br />
        <br />
        Dungeon 1-3 - Zen DROP
        <br />
        <br />
        С мобов 80-150 lvl с некоторым шансом падают куски:
        <br />
        <br />
        Ore of Level
        <br />
        Ore of Luck
        <br />
        Ore of Option
        <br />
        Ore of Skill
        <br />
        Ore of Excellent
        <br />
        Ore of Socket
        <br />
        Ore of Ancient
        <br />
        5 итемов которых соединяются в 1 КРИСТАЛЛ соответствующего вида ( кристалл не имеет шанса улучшения предмета )
        <br />
        5 Кристаллов соединяются уже в 1 камень соответствующего типа который уже вставляется в вещь и имеет 100% шанс
        эффекта
        <br />
        <br />
        Jewel of Level со 100% шансом точит вещь на +1
        <br />
        Jewel of Luck со 100% шансом добивит в вещь опцию Luck
        <br />
        Jewel of Option со 100% шансом добавит +1адд
        <br />
        Jewel of Skill со 100% шансом добавит скилл в оружие
        <br />
        Jewel of Excellent со 100% шансом добавит 1 ехе опцию в предмет но не больше 4 опций
        <br />
        Jewel of Socket Добавит в вашу вещь +1 отверстие для seed если вещь поддерживает Сокет опции.
        <br />
        Jewel of Ancient сделает ваш предмет священным. Если в данном предмете предусмотрен ancient статус (финальная
        стадия улучшения, данный предмет больше улучшить будет невозможно)
        <br />
        <br />
        5*RENA - Random Jewel DROP
        <br />
        <br />
        Lucky coin:
        <br />
        10 шт- рандом перо крест камни в том числе камень ресета
        <br />
        20 шт - камни в пачках по 10-20шт
        <br />
        30 шт - ехе Бижутерия рандом опции и lvl предмета или камни по 20-30 шт
      </p>
    </section>
  </PageBlock>
);
