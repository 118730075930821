import { Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';

import { PageBlock } from '../../styles/main-block';

export const Wings25 = () => (
  <PageBlock>
    <Typography>
      <Title level={2}>Как создать крылья 2.5 Level (Wings 2.5)</Title>
      <Title level={4}>Wing of Chaos:</Title>
      <Text type='secondary'>Blade Knight, Magic Gladiator</Text>
      <Paragraph>
        <ul>
          <li>Hell Maine&apos;s Leather</li>
          <li>Wing 2 level</li>
          <li>Jewel of Chaos</li>
          <li>Jewel of Creation</li>
          <li>50000000 Zen</li>
        </ul>
        <Text>Вероятность успеха 55%</Text>
      </Paragraph>

      <Title level={4}>Wing of Life:</Title>
      <Text type='secondary'>Fairy Elf</Text>
      <Paragraph>
        <ul>
          <li>Death Beam Knight Soul</li>
          <li>Wing 2 level</li>
          <li>Jewel of Chaos</li>
          <li>Jewel of Creation</li>
          <li>50000000 Zen</li>
        </ul>
        <Text>Вероятность успеха 55%</Text>
      </Paragraph>

      <Title level={4}>Wing of Magic:</Title>
      <Text type='secondary'>Soul Master, Magic Gladiator, Bloody Summoner</Text>
      <Paragraph>
        <ul>
          <li>Dark Phoenix Flame</li>
          <li>Wing 2 level</li>
          <li>Jewel of Chaos</li>
          <li>Jewel of Creation</li>
          <li>50000000 Zen</li>
        </ul>
        <Text>Вероятность успеха 55%</Text>
      </Paragraph>

      <Title level={4}>Cloak of Death:</Title>
      <Text type='secondary'>Dark Lord, Rage Fighter</Text>
      <Paragraph>
        <ul>
          <li>Death king&apos;s bone</li>
          <li>Wing 2 level</li>
          <li>Jewel of Chaos</li>
          <li>Jewel of Creation</li>
          <li>50000000 Zen</li>
        </ul>
        <Text>Вероятность успеха 55%</Text>
      </Paragraph>
    </Typography>
  </PageBlock>
);
