import { Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';

import { PageBlock } from '../../styles/main-block';

export const Wings4 = () => (
  <PageBlock>
    <Typography>
      <Title level={2}>Как создать крылья 4 Level (Wings 4)</Title>
      <Title level={4}>Создание Garuda Feather:</Title>
      <Paragraph>
        <ul>
          <li>Wing 3 level</li>
          <li>Bundle Jewel of Chaos (20)</li>
          <li>Bundle Jewel of Soul (20)</li>
          <li>Bundle Jewel of Bless (20)</li>
          <li>Bundle Jewel of Creation (20)</li>
          <li>75000000 Zen</li>
        </ul>
        <Text>Вероятность успеха 55%</Text>
      </Paragraph>
      <Title level={4}>Создание крыльев:</Title>
      <Paragraph>
        <ul>
          <li>Garuda Feather</li>
          <li>Bundle Jewel of Chaos (30)</li>
          <li>Bundle Jewel of Soul (30)</li>
          <li>Bundle Jewel of Bless (30)</li>
          <li>Bundle Jewel of Creation (30)</li>
          <li>Excellent item +10 +4 add</li>
          <li>Ancient item +10 +4 add</li>
          <li>175000000 Zen</li>
        </ul>
        <Text>Вероятность успеха 50%</Text>
      </Paragraph>
    </Typography>
  </PageBlock>
);
