import { Link } from 'react-router-dom';
import cn from 'classnames';

import s from './button.module.scss';

type ButtonProps = {
  className?: string;
  text: string;
  to: string;
};

export const Button = ({ className, text, to }: ButtonProps) => (
  <Link className={cn(className, s.button)} to={to}>
    {text}
  </Link>
);
