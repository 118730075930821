import { Button } from '../../shared/button';
import { Container } from '../../styles/container';
import { PageBlock } from '../../styles/main-block';

import s from './guides-page.module.scss';

export const GuidesPage = () => (
  <main>
    <Container>
      <PageBlock className={s.content}>
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
        <Button text='Рецепты' to='' />
      </PageBlock>
    </Container>
  </main>
);
