import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { LayoutRoot } from './components/layout-root';
import {
  aboutRoute,
  guidesRoute,
  questSystemRoute,
  rootRoute,
  wings3Route,
  wings4Route,
  wings25Route,
} from './constants/routes';
import { Wings3 } from './guides/wings-3';
import { Wings4 } from './guides/wings-4';
import { Wings25 } from './guides/wings-25';
import { AboutPage } from './pages/about-page';
import { GuidesPage } from './pages/guides-page';
import { QuestSystemInfoPage } from './pages/quest-system-info-page';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={rootRoute} element={<LayoutRoot />}>
          <Route path={rootRoute} element={<Navigate to={aboutRoute} />} />
          <Route path={guidesRoute} element={<GuidesPage />} />
          <Route path={questSystemRoute} element={<QuestSystemInfoPage />} />
          <Route path={aboutRoute} element={<AboutPage />} />
          <Route path={wings25Route} element={<Wings25 />} />
          <Route path={wings3Route} element={<Wings3 />} />
          <Route path={wings4Route} element={<Wings4 />} />
          <Route path='*' element={<h1>404</h1>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
