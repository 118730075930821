export const rootRoute = '/';
export const aboutRoute = '/about';
export const guidesRoute = '/guides';
export const recipesRoute = '/recipes';

export const questSystemRoute = '/guides/quest-system';
// Wings
export const wings25Route = '/guides/wings-25';
export const wings3Route = '/guides/wings-3';
export const wings4Route = '/guides/wings-4';
