import cn from 'classnames';

import s from './page-block.module.scss';

type PageBlockProps = {
  className?: string;
  children: React.ReactNode;
};

export const PageBlock = ({ className, children }: PageBlockProps) => (
  <div className={cn(className, s.block)}>{children}</div>
);
