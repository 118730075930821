import { Table } from 'antd';

import { PageBlock } from '../../styles/main-block';

import { columns, questList } from './utils/constants';

import s from './quest-system-info-page.module.scss';

export const QuestSystemInfoPage = () => (
  <PageBlock className={s.main}>
    <h1>Quest System</h1>
    <div className={s.table}>
      <Table size='middle' dataSource={questList} columns={columns} pagination={{ defaultPageSize: 50 }} />
    </div>
  </PageBlock>
);
