import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { LayoutMenu } from './elems/layout-menu';

export const LayoutRoot = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Layout.Sider>
      <Layout.Header style={{ padding: 0 }} />
      <LayoutMenu />
    </Layout.Sider>
    <Layout>
      <Layout.Header style={{ padding: 0 }} />
      <Layout.Content style={{ margin: '16px 16px 0' }}>
        <Outlet />
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>Proteus Team 2023 ©</Layout.Footer>
    </Layout>
  </Layout>
);
