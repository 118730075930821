export const columns = [
  {
    title: '№',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Monster',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Count',
    dataIndex: 'killCount',
    key: 'killCount',
  },
  {
    title: 'Reward',
    dataIndex: 'reward',
    key: 'reward',
  },
];

const quests = [
  { key: '3', killCount: 10, name: 'Spider', location: '(Lorencia 188:130)', reward: '0 STAT' },
  { key: '4', killCount: 20, name: 'Hound', location: '(Lorencia 87:137)', reward: 'Silver Medal' },
  { key: '5', killCount: 30, name: 'Elite Bull Fighter', location: '(Lorencia 63:152)', reward: 'Silver Medal' },
  { key: '6', killCount: 30, name: 'Giant', location: '(Lorencia 20:30)', reward: '5 STAT' },
  { key: '7', killCount: 50, name: 'Worm', location: '(Davias 240:80)', reward: 'Gold Medal' },
  { key: '8', killCount: 50, name: 'Assassin', location: '(Davias 35:15)', reward: 'Gold Medal' },
  { key: '9', killCount: 50, name: 'Hommerd', location: '(Davias 98:50)', reward: 'Gold Medal' },
  { key: '10', killCount: 250, name: 'Elite Yetti', location: '(Davias 211:228)', reward: '10 STAT' },
  { key: '11', killCount: 50, name: 'Chain Scorpion', location: '(Noria 213:133)', reward: 'Star of Christmas' },
  { key: '12', killCount: 50, name: 'Beetle Monster', location: '(Noria 150:110)', reward: 'Star of Christmas' },
  { key: '13', killCount: 50, name: 'Hunter', location: '(Noria 200:145)', reward: 'Star of Christmas' },
  { key: '14', killCount: 50, name: 'Stone Golem', location: '(Noria 215:123)', reward: 'Star of Christmas' },
  { key: '15', killCount: 200, name: 'Elite Goblin', location: '(Noria 165:75)', reward: '15 STAT' },
  { key: '16', killCount: 70, name: 'Shadow', location: '(LT-1)', reward: 'Pendant of Lighting' },
  { key: '17', killCount: 80, name: 'Poison Shadow', location: '(LT-2)', reward: 'Bucker +15' },
  { key: '18', killCount: 90, name: 'Cursed Wizard', location: '(LT-3)', reward: 'Ring of wizard' },
  { key: '19', killCount: 100, name: 'Death Cow', location: '(LT-3)', reward: 'Sealed Silver Box' },
  { key: '20', killCount: 110, name: 'Death Knight', location: '(LT-6)', reward: 'Silver Key' },
  { key: '21', killCount: 500, name: 'Death Gorgon', location: '(LT-7)', reward: '20 STAT' },
  { key: '22', killCount: 100, name: 'Mutant', location: '(Tarkan)', reward: 'Box of Luck' },
  { key: '23', killCount: 110, name: 'Bloody Wolf', location: '(Tarkan)', reward: 'Box of Kundun+1' },
  { key: '24', killCount: 120, name: 'Iron Wheel', location: '(Tarkan)', reward: 'Box of Kundun+2' },
  { key: '25', killCount: 130, name: 'Tantallos', location: '(Tarkan)', reward: 'Box of Kundun+2' },
  { key: '26', killCount: 140, name: 'Beam Knight', location: '(Tarkan)', reward: 'Magic Backpack' },
  { key: '27', killCount: 500, name: 'Beam Knight', location: '(Tarkan)', reward: '25 STAT' },
  { key: '28', killCount: 150, name: 'Worm', location: '(Davias 240:80)', reward: 'Box of Luck' },
  { key: '29', killCount: 160, name: 'Assassin', location: '(Davias 35:15)', reward: 'Box of Luck' },
  { key: '30', killCount: 170, name: 'Hommerd', location: '(Davias 98:50)', reward: 'Box of Luck' },
  { key: '31', killCount: 250, name: 'Elite Yetti', location: '(Davias 211:228)', reward: '30 STAT' },
  { key: '32', killCount: 180, name: 'Forest Orc', location: '(Aida)', reward: 'Sealed Golden Box' },
  { key: '33', killCount: 190, name: 'Death Tree', location: '(Aida)', reward: 'Golden Key' },
  { key: '34', killCount: 200, name: 'Death Rider', location: '(Aida)', reward: 'Box of Luck' },
  { key: '35', killCount: 210, name: 'Blue Golem', location: '(Aida)', reward: 'Magic Backpack' },
  { key: '36', killCount: 30, name: 'Zaikan', location: '(Tarkan 2)', reward: '35 STAT' },
  { key: '37', killCount: 220, name: 'Shadow', location: '(LT-1)', reward: 'Lucky Coin' },
  { key: '38', killCount: 230, name: 'Poison Shadow', location: '(LT-2)', reward: 'Rena' },
  { key: '39', killCount: 240, name: 'Cursed Wizard', location: '(LT-3)', reward: 'Ring of wizard' },
  { key: '40', killCount: 250, name: 'Devil', location: '(LT-5)', reward: 'Sealed Silver Box' },
  { key: '41', killCount: 260, name: 'Death Knight', location: '(LT-6)', reward: 'Silver Key' },
  { key: '42', killCount: 500, name: 'Death Gorgon', location: '(LT-7)', reward: '40 STAT' },
  { key: '43', killCount: 270, name: 'Alquamos', location: '(Icarus)', reward: 'Lucky Coin' },
  { key: '44', killCount: 280, name: 'Queen Rainier', location: '(Icarus)', reward: 'Sealed Golden Box' },
  { key: '45', killCount: 290, name: 'Mega Crust', location: '(Icarus)', reward: 'Golden Key' },
  { key: '46', killCount: 300, name: 'Alpha Crust', location: '(Icarus)', reward: 'Pet Panda' },
  { key: '47', killCount: 310, name: 'Drakan', location: '(Icarus)', reward: 'Chaos Card  Mini' },
  { key: '48', killCount: 500, name: 'Phantom Knight', location: '(Icarus)', reward: '45 STAT' },
  { key: '49', killCount: 200, name: 'Grizzly', location: '(Elbeland)', reward: 'Jewel of Life' },
  { key: '50', killCount: 200, name: 'Captain Grizzly', location: '(Elbeland)', reward: 'Chaos Card  Mini' },
  { key: '51', killCount: 200, name: 'Strange Rabbit', location: '(Elbeland)', reward: 'Chaos Card  Mini' },
  { key: '52', killCount: 200, name: 'Curser Lich', location: '(Elbeland)', reward: 'Chaos Card  Mini' },
  { key: '53', killCount: 200, name: 'Werewolf', location: '(Elbeland)', reward: 'Chaos Card  Mini' },
  { key: '54', killCount: 50, name: 'Witch Queen', location: '(Aida)', reward: '50 STAT' },
  { key: '55', killCount: 200, name: 'Chief Skeleton Warrior', location: '(BC+8)', reward: 'Chaos Card  Mini' },
  { key: '56', killCount: 5, name: 'Illusion Sorcerer Spirit', location: '(Noria)', reward: 'Bundled Jewel of Bless' },
  { key: '57', killCount: 200, name: 'Chief Skeleton Archer', location: '(BC+8)', reward: 'Chaos Card  Mini' },
  { key: '58', killCount: 5, name: 'Illusion Sorcerer Spirit', location: '(Davias)', reward: 'Bundled Jewel of Soul' },
  {
    key: '59',
    killCount: 5,
    name: 'Illusion Sorcerer Spirit',
    location: '(Atlans)',
    reward: 'Bundled Jewel of Chaos',
  },
  { key: '60', killCount: 1, name: 'Illusion Sorcerer Spirit', location: '(Arena)', reward: 'Pet Unicorn' },
  { key: '61', killCount: 1, name: 'Golden Goblin', location: '(Event)', reward: '55 STAT' },
  { key: '62', killCount: 1, name: 'Golden Solider', location: '(Event)', reward: 'Jewel of Option' },
  { key: '63', killCount: 200, name: 'Bahamut', location: '(Atlans 43:10)', reward: 'Lucky Item set' },
  { key: '64', killCount: 200, name: 'Vepar', location: '(Atlans 23:42)', reward: 'Lucky Item set' },
  { key: '65', killCount: 200, name: 'Valkyrie', location: '(Atlans 45:70)', reward: 'Lucky Item set' },
  { key: '66', killCount: 200, name: 'Great Bahamut', location: '(Atlans 2)', reward: 'Lucky Item set' },
  { key: '67', killCount: 200, name: 'Silver Valkyrie', location: '(Atlans 2)', reward: 'Lucky Item set' },
  { key: '68', killCount: 500, name: 'Hydra', location: '(Atlans 30:230)', reward: '60 STAT' },
  { key: '69', killCount: 320, name: 'Alquamos', location: '(Icarus)', reward: '20 STAT' },
  { key: '70', killCount: 330, name: 'Queen Rainier', location: '(Icarus)', reward: 'Chaos Card  Mini' },
  { key: '71', killCount: 340, name: 'Mega Crust', location: '(Icarus)', reward: 'Blood Castle Ticket' },
  { key: '72', killCount: 350, name: 'Alpha Crust', location: '(Icarus)', reward: 'Devil Square Ticket' },
  { key: '73', killCount: 500, name: 'Phantom Knight', location: '(Icarus)', reward: '65 STAT' },
  { key: '74', killCount: 230, name: 'Poison Shadow', location: '(LT-2)', reward: 'Golden Coin' },
  { key: '75', killCount: 240, name: 'Cursed Wizard', location: '(LT-3)', reward: 'Ring of wizard' },
  { key: '76', killCount: 250, name: 'Devil', location: '(LT-5)', reward: 'Pet Panda' },
  { key: '77', killCount: 260, name: 'Death Knight', location: '(LT-6)', reward: 'Panda Ring' },
  { key: '78', killCount: 500, name: 'Death Gorgon', location: '(LT-7)', reward: '70 STAT' },
  { key: '79', killCount: 360, name: 'Berserk', location: '(Kanturu)', reward: 'Chaos Card  Mini' },
  { key: '80', killCount: 370, name: 'Sprinter Wolf', location: '(Kanturu)', reward: 'Chaos Card  Mini' },
  { key: '81', killCount: 380, name: 'Iron Rider', location: '(Kanturu)', reward: '50 STAT' },
  { key: '82', killCount: 500, name: 'Blade Hunter', location: '(Kanturu)', reward: '75 STAT' },
  { key: '83', killCount: 100, name: 'Chain Scorpion', location: '(Noria 213:133)', reward: 'Box of Kundun +3' },
  { key: '84', killCount: 100, name: 'Beetle Monster', location: '(Noria 150:110)', reward: '10 STAT' },
  { key: '85', killCount: 100, name: 'Hunter', location: '(Noria 200:145)', reward: 'Box of Kundun +4' },
  { key: '86', killCount: 100, name: 'Stone Golem', location: '(Noria 215:123)', reward: 'Pink Chocolate Box' },
  { key: '87', killCount: 1, name: 'Golden Tantallos', location: '(Event)', reward: '80 STAT' },
  { key: '88', killCount: 100, name: 'Poison Shadow', location: '(LT-2)', reward: '10 STAT' },
  { key: '89', killCount: 100, name: 'Cursed Wizard', location: '(LT-3)', reward: '10 STAT' },
  { key: '90', killCount: 100, name: 'Death Cow', location: '(LT-3)', reward: '10 STAT' },
  { key: '91', killCount: 100, name: 'Death Knight', location: '(LT-6)', reward: '10 STAT' },
  { key: '92', killCount: 500, name: 'Death Gorgon', location: '(LT-7)', reward: '85 STAT' },
  { key: '93', killCount: 100, name: 'Red Skeleton Knight', location: '(BC+8)', reward: 'Jewel of Level' },
  { key: '94', killCount: 100, name: 'Magic Skeleton', location: '(BC+8)', reward: 'Jewel of Luck' },
  { key: '95', killCount: 420, name: 'Satyros', location: '(Kanturu)', reward: 'Bundled Jewel of life' },
  { key: '96', killCount: 440, name: 'Gigantis', location: '(Kanturu)', reward: 'Box of Kundun +5' },
  { key: '97', killCount: 450, name: 'Genocider', location: '(Kanturu)', reward: 'Lucky Coin' },
  { key: '98', killCount: 100, name: 'Schriker', location: '(Kalima 5)', reward: '90 STAT' },
  {
    key: '99',
    killCount: 20,
    name: 'Illusion Sorcerer Spirit',
    location: '(Atlans)',
    reward: 'Bundled Jewel of Harmony',
  },
  { key: '100', killCount: 1, name: 'Illusion Sorcerer Spirit', location: '(Arena)', reward: 'Platinum Coin' },
  { key: '101', killCount: 10, name: 'Illusion Sorcerer Spirit', location: '(Arena)', reward: 'Jewel of Excellent' },
  { key: '102', killCount: 10, name: 'Spider', location: '(Lorencia)', reward: '100 STAT' },
  { key: '103', killCount: 100, name: 'Chief Skeleton Warrior', location: '(BC+8)', reward: '50 STAT' },
  {
    key: '104',
    killCount: 20,
    name: 'Illusion Sorcerer Spirit',
    location: '(Noria)',
    reward: 'Bundled Jewel of Bless',
  },
  { key: '105', killCount: 100, name: 'Chief Skeleton Archer', location: '(BC+8)', reward: '50 STAT' },
  {
    key: '106',
    killCount: 20,
    name: 'Illusion Sorcerer Spirit',
    location: '(Davias)',
    reward: 'Bundled Jewel of Soul',
  },
  { key: '107', killCount: 500, name: 'Persona', location: '(Kanturu Relic)', reward: '105 STAT' },
  { key: '108', killCount: 520, name: 'Twin Tail', location: '(Relic)', reward: 'Bundled Jewel of life' },
  { key: '109', killCount: 530, name: 'Gigantis', location: '(Relic)', reward: 'Box of Kundun +5' },
  { key: '110', killCount: 540, name: 'Sapi-Tres', location: '(Swamp)', reward: 'Lucky Coin' },
  { key: '111', killCount: 555, name: 'Dreadfear', location: '(Relic)', reward: 'Pet Unicorn' },
  { key: '112', killCount: 2, name: 'Golden Goblin', location: '(Event)', reward: '110 STAT' },
  { key: '113', killCount: 600, name: 'Bone Scorpion', location: '(Karutan)', reward: '25 STAT' },
  { key: '114', killCount: 610, name: 'Berserk', location: '(Kanturu)', reward: '25 STAT' },
  { key: '115', killCount: 620, name: 'Sprinter Wolf', location: '(Kanturu)', reward: '25 STAT' },
  { key: '116', killCount: 630, name: 'Iron Rider', location: '(Kanturu)', reward: '25 STAT' },
  { key: '117', killCount: 1000, name: 'Blade Hunter', location: '(Kanturu)', reward: '115 STAT' },
  { key: '118', killCount: 1000, name: 'Dark Skull Soldier', location: '(BC+8)', reward: 'Jewel of Level' },
  { key: '119', killCount: 1000, name: 'Giant Ogre', location: '(BC+8)', reward: 'Jewel of Luck' },
  { key: '120', killCount: 1000, name: 'Red Skeleton Knight', location: '(BC+8)', reward: 'Jewel of Level' },
  { key: '121', killCount: 1000, name: 'Magic Skeleton', location: '(BC+8)', reward: 'Jewel of Luck' },
  { key: '122', killCount: 1000, name: 'Sapi-Duo', location: '(Swamp)', reward: '120 STAT' },
  { key: '123', killCount: 720, name: 'Alquamos', location: '(Icarus)', reward: 'Red Chocolate Box' },
  { key: '124', killCount: 730, name: 'Queen Rainier', location: '(Icarus)', reward: 'Red Chocolate Box' },
  { key: '125', killCount: 740, name: 'Mega Crust', location: '(Icarus)', reward: 'Red Chocolate Box' },
  { key: '126', killCount: 750, name: 'Alpha Crust', location: '(Icarus)', reward: 'Red Chocolate Box' },
  { key: '127', killCount: 1000, name: 'Phantom Knight', location: '(Icarus)', reward: '125 STAT' },
  { key: '128', killCount: 750, name: 'Shadow Pawn', location: '(Swamp)', reward: 'Chaos Card  Gold' },
  { key: '129', killCount: 760, name: 'Shadow Knight', location: '(Swamp)', reward: 'Chaos Card  Gold' },
  { key: '130', killCount: 770, name: 'Shadow Look', location: '(Swamp)', reward: 'Chaos Card  Gold' },
  { key: '131', killCount: 780, name: 'Thunder Napin', location: '(Swamp)', reward: 'Chaos Card  Gold' },
  { key: '132', killCount: 1000, name: 'Ghost Napin', location: '(Swamp)', reward: '130 STAT' },
  { key: '133', killCount: 800, name: 'Ice Walker', location: '(Raklion)', reward: 'Pet Panda' },
  { key: '134', killCount: 820, name: 'Ice Giant', location: '(Raklion)', reward: 'Panda Ring' },
  { key: '135', killCount: 840, name: 'Coolutin', location: '(Raklion)', reward: 'Demon' },
  { key: '136', killCount: 860, name: 'Giant Mammoth', location: '(Raklion)', reward: 'Chaos Card  Gold' },
  { key: '137', killCount: 1000, name: 'Iron Knight', location: '(Raklion)', reward: '135 STAT' },
  { key: '138', killCount: 900, name: 'Twin Tail', location: '(Relic)', reward: 'Bundled Jewel of Harmony' },
  { key: '139', killCount: 900, name: 'Gigantis', location: '(Relic)', reward: '50 STAT' },
  { key: '140', killCount: 900, name: 'Sapi-Tres', location: '(Swamp)', reward: 'Chaos Card  Gold' },
  { key: '141', killCount: 900, name: 'Dreadfear', location: '(Relic)', reward: 'Pet Unicorn' },
  { key: '142', killCount: 1, name: 'Golden Tantallos', location: '(Event)', reward: '140 STAT' },
  { key: '143', killCount: 900, name: 'Aegis', location: '(Kalima 7)', reward: 'Golden Coin' },
  { key: '144', killCount: 920, name: 'Rogue Centurion', location: '(Kalima 7)', reward: '50 STAT' },
  { key: '145', killCount: 940, name: 'Bloody Soldier', location: '(Kalima 7)', reward: 'Golden Coin' },
  { key: '146', killCount: 960, name: 'Death Angel', location: '(Kalima 7)', reward: 'Golden Coin' },
  { key: '147', killCount: 1000, name: 'Iron Knight', location: '(Raklion)', reward: '145 STAT' },
  { key: '148', killCount: 1000, name: 'Berserker Warrior', location: '(Kanturu 3)', reward: 'Jewel of Option' },
  { key: '149', killCount: 1000, name: 'Kentauros Warrior', location: '(Kanturu 3)', reward: 'Jewel Of Skill' },
  { key: '150', killCount: 1000, name: 'Gigantis Warrior', location: '(Kanturu 3)', reward: 'Jewel of Option' },
  { key: '151', killCount: 1000, name: 'Genocider Warrior', location: '(Kanturu 3)', reward: 'Jewel Of Skill' },
  { key: '152', killCount: 1, name: 'Golden Twin Tail', location: '(Kanturu Relic)', reward: '150 STAT' },
  { key: '153', killCount: 1000, name: 'Zombie Fighter', location: '(Vulcanus)', reward: 'Bundled Jewel of Creation' },
  { key: '154', killCount: 1000, name: 'Zombier', location: '(Vulcanus)', reward: '50 STAT' },
  { key: '155', killCount: 1000, name: 'Gladiator', location: '(Vulcanus)', reward: 'Lucky Coin' },
  { key: '156', killCount: 1000, name: 'Hell Gladiator', location: '(Vulcanus)', reward: 'Jewel of Option' },
  { key: '157', killCount: 1000, name: 'Shadow', location: '(LT-1)', reward: '50 STAT' },
  { key: '158', killCount: 1000, name: 'Poison Shadow', location: '(LT-2)', reward: '50 STAT' },
  { key: '159', killCount: 1000, name: 'Cursed Wizard', location: '(LT-3)', reward: '50 STAT' },
  {
    key: '160',
    killCount: 50,
    name: 'Illusion Sorcerer Spirit',
    location: '(Atlans)',
    reward: 'Bundled Jewel of Harmony',
  },
  { key: '161', killCount: 5, name: 'Illusion Sorcerer Spirit', location: '(Arena)', reward: 'Golden Coin' },
  { key: '162', killCount: 1, name: 'Kundun', location: '(BOSS)', reward: 'Jewel of Excellent' },
  { key: '163', killCount: 1500, name: 'Butcher', location: '(Vulcanus)', reward: 'Bundled Jewel of Creation' },
  { key: '164', killCount: 1500, name: 'Butcher Ashes', location: '(Vulcanus)', reward: 'Bundled Jewel of Bless' },
  { key: '165', killCount: 1500, name: 'Bloody Assassin', location: '(Vulcanus)', reward: 'Bundled Jewel of Soul' },
  { key: '166', killCount: 5000, name: 'Cold-Blooded', location: '(Vulcanus)', reward: 'Blue Chocolate Box' },
  { key: '167', killCount: 500, name: 'Grizzly', location: '(Elbeland)', reward: 'Jewel of Life' },
  { key: '168', killCount: 500, name: 'Captain Grizzly', location: '(Elbeland)', reward: 'Lucky Coin' },
  { key: '169', killCount: 500, name: 'Strange Rabbit', location: '(Elbeland)', reward: 'Rena' },
  { key: '170', killCount: 500, name: 'Curser Lich', location: '(Elbeland)', reward: '50 STAT' },
  { key: '171', killCount: 500, name: 'Werewolf', location: '(Elbeland)', reward: '50 STAT' },
  { key: '172', killCount: 5000, name: 'Ghost Napin', location: '(Swamp)', reward: '300 STAT' },
  { key: '173', killCount: 1000, name: 'Chief Skeleton Warrior', location: '(BC+8)', reward: '50 STAT' },
  {
    key: '174',
    killCount: 50,
    name: 'Illusion Sorcerer Spirit',
    location: '(Noria)',
    reward: 'Bundled Jewel of Bless',
  },
  { key: '175', killCount: 1000, name: 'Chief Skeleton Archer', location: '(BC+8)', reward: '50 STAT' },
  {
    key: '176',
    killCount: 50,
    name: 'Illusion Sorcerer Spirit',
    location: '(Davias)',
    reward: 'Bundled Jewel of Soul',
  },
  { key: '177', killCount: 1, name: 'Golden Crust', location: '(Event)', reward: 'Bundled Jewel of Harmony' },
  { key: '178', killCount: 1000, name: 'Dark Skull Soldier', location: '(BC+8)', reward: 'Jewel of Level' },
  { key: '179', killCount: 1000, name: 'Giant Ogre', location: '(BC+8)', reward: 'Jewel of Luck' },
  { key: '180', killCount: 1000, name: 'Red Skeleton Knight', location: '(BC+8)', reward: 'Jewel of Level' },
  { key: '181', killCount: 1000, name: 'Magic Skeleton', location: '(BC+8)', reward: 'Jewel of Luck' },
  { key: '182', killCount: 1000, name: 'Sapi-Duo', location: '(Swamp)', reward: '350 STAT' },
  { key: '183', killCount: 2000, name: 'Burning Lava Giant', location: '(Vulcanus)', reward: 'Chaos Card  Rare' },
  { key: '184', killCount: 2000, name: 'Lava Giant', location: '(Vulcanus)', reward: 'Chaos Card  Rare' },
  { key: '185', killCount: 2000, name: 'Dark Mammoth', location: '(Lacleon)', reward: 'Chaos Card  Rare' },
  { key: '186', killCount: 2000, name: 'Dark Giant', location: '(Lacleon)', reward: 'Chaos Card  Rare' },
  { key: '187', killCount: 2000, name: 'Dark Coolutin', location: '(Lacleon)', reward: 'Chaos Card  Rare' },
  { key: '188', killCount: 2000, name: 'Dark Iron Knight', location: '(Lacleon)', reward: 'Chaos Card  Rare' },
  { key: '189', killCount: 2000, name: 'Great Drakan', location: '(Icarus)', reward: 'Chaos Card  Rare' },
  { key: '190', killCount: 2000, name: 'Dark Phoenix', location: '(Icarus)', reward: 'Chaos Card  Rare' },
  { key: '191', killCount: 2000, name: 'Hell Maine', location: '(Aida)', reward: 'Chaos Card  Rare' },
  { key: '192', killCount: 1, name: 'Selupan', location: '(BOSS)', reward: 'Condor Feather' },
  { key: '193', killCount: 2500, name: 'Bone Scorpion', location: '(Karutan)', reward: '25 STAT' },
  { key: '194', killCount: 2500, name: 'Berserk', location: '(Kanturu)', reward: '25 STAT' },
  { key: '195', killCount: 2500, name: 'Sprinter Wolf', location: '(Kanturu)', reward: '25 STAT' },
  { key: '196', killCount: 1000, name: 'Schriker', location: '(Kalima7)', reward: '25 STAT' },
  { key: '197', killCount: 1, name: 'Nightmare', location: '(Kanturu Relic)', reward: 'Blue Chocolate Box' },
  { key: '198', killCount: 5000, name: 'Hero Mutant', location: '(Arkania)', reward: 'Condor Flame' },
  { key: '199', killCount: 5000, name: 'Omega Wing', location: '(Arkania)', reward: 'Fragment of Horn' },
  { key: '200', killCount: 5000, name: 'Axe Warrior Hero', location: '(Arkania)', reward: 'Broken Horn' },
  { key: '201', killCount: 5000, name: 'Gigas Golem', location: '(Arkania)', reward: 'Blue Chocolate Box' },
  { key: '202', killCount: 1, name: 'Gaion Kharein', location: '(Event)', reward: 'Garuda Feather' },
  { key: '203', killCount: 1, name: 'Skeleton King', location: '(Event lorencia)', reward: 'Jewel of Socket' },
  { key: '204', killCount: 50000, name: 'Red Skeleton Knight', location: '(BC+8)', reward: 'Jewel of Socket' },
  {
    key: '205',
    killCount: 40,
    name: 'Nix(Guard)',
    location: '(Nixie Lake)',
    reward: '300 STAT 300kk zen 1000WCC 500WCP 10.000GP',
  },
  { key: '206', killCount: 5, name: 'Frost(Boss)', location: '(Nixie Lake)', reward: 'Awakening Soul Wing' },
  {
    key: '207',
    killCount: 1,
    name: 'MEGABOSS',
    location: '(Tarkan)',
    reward: '300 STAT 300kk zen 1000WCC 500WCP 10.000GP',
  },
];

export const questList = quests.map((quest, index) => ({ ...quest, key: index + 1 }));
