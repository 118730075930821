import { Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';

import { PageBlock } from '../../styles/main-block';

export const Wings3 = () => (
  <PageBlock>
    <Typography>
      <Title level={2}>Как создать крылья 3 Level (Wings 3)</Title>
      <Title level={4}>Создание Feather of Condor:</Title>
      <Paragraph>
        <ul>
          <li>Ancient item +7 +Options</li>
          <li>Bundle of Jewel of Soul (10)</li>
          <li>Wing 2 lvl +9 +Options</li>
          <li>Jewel of Chaos</li>
          <li>Jewel of Creation</li>
        </ul>
        {/* <Text>Вероятность успеха 55%</Text> */}
      </Paragraph>
      <Title level={4}>Создание крыльев:</Title>
      <Paragraph>
        <ul>
          <li>Condor Feather</li>
          <li>Condor Flame</li>
          <li>Excellent item +9 +Options</li>
          <li>Bundle of Jewel of Soul (10)</li>
          <li>Bundle of Jewel of Bless (10)</li>
          <li>Jewel of Chaos</li>
          <li>Jewel of Creation</li>
        </ul>
        {/* <Text>Вероятность успеха 55%</Text> */}
      </Paragraph>
    </Typography>
  </PageBlock>
);
