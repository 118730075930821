import { useNavigate } from 'react-router-dom';
import { Menu, MenuProps } from 'antd';

import { aboutRoute, questSystemRoute, wings3Route, wings4Route, wings25Route } from '../../../../constants/routes';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const recipesWings: MenuItem[] = [
  getItem('Wings 2.5', wings25Route),
  getItem('Wings 3', wings3Route),
  getItem('Wings 4', wings4Route),
];

const recipes: MenuItem[] = [getItem('Крылья', 'recipesSub1', null, recipesWings)];

const items: MenuItem[] = [
  getItem('О сервере', aboutRoute),
  getItem('Система квестов', questSystemRoute),
  getItem('Рецепты', 'sub1', null, recipes),
];

export const LayoutMenu = () => {
  const navigate = useNavigate();

  return (
    <Menu theme='dark' defaultSelectedKeys={['1']} mode='inline' items={items} onClick={({ key }) => navigate(key)} />
  );
};
